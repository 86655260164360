/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import bgFront from "/assets/img/bg-contact-us-section.jpg";
import TourProject from "/assets/img/tour-project.jpg";
import { robotoSlab } from "/utils/fonts";

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const TouchAppRounded = dynamic(() => import('@mui/icons-material/TouchAppRounded'), { loading: () => null });
const MDBadge = dynamic(() => import('/components/MDBadge'), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });
const ALLDVideo = dynamic(() => import('/examples/ALLDVideo'), { loading: () => null });
const RotatingCard = dynamic(() => import('/examples/RotatingCard'), { loading: () => null });
const RotatingCardFront = dynamic(() => import('/examples/RotatingCard/RotatingCardFront'), { loading: () => null });
const RotatingCardBack = dynamic(() => import('/examples/RotatingCard/RotatingCardBack'), { loading: () => null });

function Information() {
  const [controller] = useMaterialUIController();
  return (
    <MDBox component="section" pb={6} my={6} sx={{ pt: { xs: 1, lg: 3 } }}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={7}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MDBadge
            variant="contained"
            color={controller.sidenavColor}
            badgeContent="Our Experience"
            container
            sx={{ mb: 2 }}
          />
          <MDTypography variant="h2" fontWeight="bold"
            className={robotoSlab.className}
          >
            Landscaping Long Island for 30+ Years
          </MDTypography>
          <MDTypography variant="body1" color="text">
            We take pride in our work and are committed to delivering excellence.
          </MDTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={12} spacing={3} alignItems="flex-start" >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon={<TouchAppRounded />}
                color="dark"
                title={
                  <>
                    30+ Years
                    <br />
                    Building
                  </>
                }
                description="Autumn Leaf has been designing and building landscapes on Long Island for more than 30 years. We take pride in our work and are committed to excellence and customer satisfaction."
              />
              <RotatingCardBack
                image={bgFront}
                color="dark"
                title="Interested?"
                description={`Let us show you what your vision can be for your property. Call us or send us a message to get started.`}
                action={{
                  type: "internal",
                  route: "/#message",
                  label: "contact",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <MDBox mt={{ xs: 3, sm: 0 }}>
              <MDTypography variant="h4">Tour a project</MDTypography>
              <MDTypography
                variant="body2"
                mb={3}
                fontWeight="regular"
              >
                This project features a pavilion with an outdoor kitchen, patio and pool, a waterfall, pond and plantings.
              </MDTypography>
              <ALLDVideo row={{
                url: TourProject,
                title: "Tour Project",
                vid: "0W1I9NVliP8",
              }} />
            </MDBox>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Information;
